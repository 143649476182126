<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Set Up Service Description
            </h1>
            <p
              v-if="serviceDescription.createdAt"
              class="log_info"
            >
              Created by {{ serviceDescription.createdBy ? serviceDescription.createdBy.name : '' }} on {{ dateFormatWithTime(serviceDescription.createdAt) }} <br>Last updated on {{ dateFormatWithTime(serviceDescription.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="serviceDescriptionForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Custom Form
            </h3>
          </template>
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
          >
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Parent"
                vid="parent"
                rules=""
              >
                <b-form-group
                  label="Parent"
                  :label-for="'h-activity-type-parent' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    :id="'h-activity-type-parent' + index"
                    v-model="field.parent"
                    label="label"
                    placeholder="Select from list"
                    :options="parentComponents"
                    :reduce="el => el.id"
                    :selectable="option => option.id != field.id"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Component Type"
                vid="prospectBookable"
                rules="required"
              >
                <b-form-group
                  label="Component Type*"
                  :label-for="'h-activity-type-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.inputTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-activity-type-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.inputTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.inputTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
            <b-col
              v-if="field.type == 'file'"
              cols="md-8"
            >
              <b-form-group
                label="Upload Image*"
                :label-for="'h-activity-type-file' + index"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Image"
                  vid="h-activity-type-file"
                  rules=""
                >
                  <b-form-file
                    :id="'h-activity-type-file' + index"
                    v-model="field.file"
                    accept="image/*"
                    @input="setImage(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.fileValidation"
                    class="text-danger"
                  >
                    {{ field.fileValidationMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="field.type == 'file'"
              cols="md-4"
            >

              <b-img
                thumbnail
                fluid
                width="200px"
                height="auto"
                :src="field.base64File"
              />
            </b-col>

            <b-col cols="12">
              <div
                v-if="field.type == 'title-and-text'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Title*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Title"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />

                <b-col cols="md-8">
                  <b-form-group
                    label="Text"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-activity-type-description' + index"
                      v-model="field.description"
                      placeholder="Text"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />
              </div>
            </b-col>

            <b-col cols="12">
              <div
                v-if="field.type == 'short-answer' || field.type == 'increment' || field.type == 'long-answer' || field.type == 'single-select' || field.type == 'multi-select' || field.type == 'dropdown'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-6">
                  <b-form-group
                    label="Base Label"
                    :label-for="'h-activity-type-base-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Base Label"
                      vid="baseLabel"
                      rules=""
                    >
                      <b-form-input
                        :id="'h-activity-type-base-label' + index"
                        v-model="field.baseLabel"
                        placeholder="Field Base Label"
                        :state="(errors.length > 0 || field.baseLabelValidation) ? false : null"
                        name="baseLabel"
                        @input="field.baseLabelValidation == true ? field.baseLabelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.baseLabelValidation"
                        class="text-danger"
                      >
                        {{ field.baseLabelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-8">
                  <b-form-group
                    label="Description"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-activity-type-description' + index"
                      v-model="field.description"
                      placeholder="Description"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />

                <b-col
                  v-if="field.type == 'multi-select' || field.type == 'dropdown'"
                  cols="md-12"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-activity-type-extraOptions' + index"
                    label-cols-md="2"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-form-input
                          :id="'h-activity-type-label' + index + 'extraOptionsKey' + key"
                          v-model="opt.key"
                          placeholder="Type an option Name"
                          :state="(errors.length > 0 || opt.keyValidation) ? false : null"
                          name="key"
                          class="mr-2"
                          style="width: 40%;"
                          @input="opt.keyValidation == true ? opt.keyValidation = false : null"
                        />
                        <b-form-input
                          :id="'h-activity-type-label' + index + 'extraOptions' + key"
                          v-model="opt.value"
                          placeholder="Add a text to display in Calendar Title"
                          :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                          name="value"
                          class="mr-2"
                          style="width: 40%;"
                          @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                        />
                        <b-form-checkbox
                          v-model="opt.showInFrontend"
                          value="yes"
                          class="mt-50"
                          style="width: 18%;"
                        >
                          Visible to customer/ agent
                        </b-form-checkbox>
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-secondary"
                          type="button"
                          class="btn-icon rounded-circle"
                          style="width: 1%;"
                          @click="removeCustomFieldOptions(index, key)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                >
                  <b-form-group
                    label="User Can Select*"
                    :label-for="'h-activity-type-multiSelectType' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="User Can Select"
                      vid="multiSelectType"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-multiSelectType' + index"
                        v-model="field.multiSelectType"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in multiSelectOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.multiSelectType === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.multiSelectTypeInvalid"
                        class="text-danger"
                      >
                        {{ field.multiSelectTypeInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                />
                <b-col
                  v-if="field.type == 'increment'"
                  cols="md-6"
                >
                  <b-form-group
                    v-for="(opt, key) in field.extraOptions"
                    :key="key"
                    :label="opt.key + '*'"
                    :label-for="'h-increments-label' + key"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="opt.key"
                      :vid="opt.key"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-increments-label' + key"
                        v-model="opt.value"
                        placeholder="0"
                        :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                        name="label"
                        @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'increment'"
                  cols="md-6"
                />

                <b-col cols="md-6">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-activity-type-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-6">
                  <b-form-group
                    label="Kitchen Item?*"
                    :label-for="'h-activity-type-kitchenItem' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kitchen Item"
                      vid="kitchenItem"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-kitchenItem' + index"
                        v-model="field.kitchenItem"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.kitchenItem === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-6">
                  <b-form-group
                    label="Visible to customer/ agents?*"
                    :label-for="'h-activity-type-visible' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Visible to customer/ agents"
                      vid="visible"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-visible' + index"
                        v-model="field.showInFrontend"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.showInFrontend === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
              </div>
            </b-col>

            <b-col cols="12">
              <p
                class="removeText"
                @click="removeCustomField(index)"
              >
                Remove this component
              </p>

              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <span class="align-middle">Add Custom Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add Custom Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormRadioGroup, BForm, BButton, BNav, BFormFile, BImg,
  BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormRadioGroup,
    BForm,
    BButton,
    BNav,
    BImg,
    BFormRadio,
    BFormCheckbox,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      serviceDescription: {},
      trueFalseOptions: [{ title: 'Yes', code: 'yes' }, { title: 'No', code: 'no' }],
      inputTypeOptions: [
        { title: 'Image', code: 'file' },
        { title: 'Title and Text', code: 'title-and-text' },
        { title: 'Quantity', code: 'increment' },
        { title: 'Short Answer Field', code: 'short-answer' },
        { title: 'Long Answer Field', code: 'long-answer' },
        { title: 'Selection List Field', code: 'multi-select' },
        { title: 'Dropdown Field', code: 'dropdown' },
        { title: 'Single Checkbox Field', code: 'single-select' },
      ],
      customFields: [],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      multiSelectOptions: [
        { text: 'One Option Only', value: 'single' },
        { text: 'Multiple Options', value: 'multiple' },
      ],
      statusOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Draft', code: 'draft' },
      ],
      // validation rules
      required,
    }
  },
  computed: {
    parentComponents() {
      const selectableItems = this.customFields.filter(o => o.type === 'title-and-text' && o.label)
      return selectableItems
    },
  },
  created() {
    this.$http.get('operation/service-descriptions')
      .then(response => {
        this.serviceDescription = response.data
        this.setCustomField()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    setCustomField() {
      if (this.serviceDescription.customFields && this.serviceDescription.customFields.length) {
        const fullData = this.serviceDescription.customFields.map(field => {
          const extraOptionsData = field.extraOptions.map(option => {
            const optionData = {
              key: option.key,
              keyInvalid: false,
              keyInvalidMessage: '',
              value: option.value,
              valueInvalid: false,
              valueInvalidMessage: '',
              showInFrontend: option.showInFrontend,
              kitchenItem: option.kitchenItem,
            }
            return optionData
          })

          const properties = {
            id: field.id ? field.id : uuidv4(),
            parent: field.parent ? field.parent : '',
            type: field.type,
            label: field.label,
            baseLabel: field.baseLabel,
            file: null,
            base64File: field.file,
            description: field.description,
            required: field.required,
            displayInCalendar: field.displayInCalendar,
            extraOptions: extraOptionsData,
            multiSelectType: field.multiSelectType,
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
            displayInCalendarInvalid: false,
            displayInCalendarInvalidMessage: '',
            multiSelectTypeInvalid: false,
            multiSelectTypeInvalidMessage: '',
            showInFrontend: field.showInFrontend,
            kitchenItem: field.kitchenItem,
          }
          return properties
        })

        this.customFields = fullData ?? []
      }
    },
    addCustomField() {
      this.customFields.push({
        id: uuidv4(),
        parent: '',
        type: 'title-and-text',
        label: '',
        baseLabel: '',
        description: '',
        required: 'yes',
        kitchenItem: 'yes',
        displayInCalendar: 'yes',
        extraOptions: [],
        file: null,
        base64File: null,
        multiSelectType: 'single',
        typeInvalid: false,
        typeInvalidMessage: '',
        fileInvalid: false,
        fileInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
        displayInCalendarInvalid: false,
        displayInCalendarInvalidMessage: '',
        multiSelectTypeInvalid: false,
        multiSelectTypeInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
        showInFrontend: 'yes',
        kitchenItem: 'yes',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].inputTypeInvalid === true ? this.customFields[index].inputTypeInvalid = false : null
      this.customFields[index].extraOptions = []
      if (this.customFields[index].type === 'multi-select' || this.customFields[index].type === 'dropdown') {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
          showInFrontend: 'yes',
          kitchenItem: 'yes',
        })
      } else if (this.customFields[index].type === 'increment') {
        this.customFields[index].extraOptions.push({
          key: 'Min Quantity',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: 0,
          valueInvalid: false,
          valueInvalidMessage: '',
          showInFrontend: 'yes',
          kitchenItem: 'yes',
        })
        this.customFields[index].extraOptions.push({
          key: 'Max Quantity',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: 10,
          valueInvalid: false,
          valueInvalidMessage: '',
          showInFrontend: 'yes',
          kitchenItem: 'yes',
        })
        this.customFields[index].extraOptions.push({
          key: 'Increments',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: 1,
          valueInvalid: false,
          valueInvalidMessage: '',
          showInFrontend: 'yes',
          kitchenItem: 'yes',
        })
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Remove will <strong>permanently delete this component / custom field</strong>. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.customFields.splice(index, 1)
          }
        })
    },

    setImage(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.customFields[index].file)
      reader.onload = () => {
        this.customFields[index].base64File = reader.result
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.setCustomField()
          }
        })
    },
    submitForm() {
      this.$refs.serviceDescriptionForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const trimmedData = this.customFields.map(field => {
            const extraOptionsData = field.extraOptions.map(option => {
              const optionData = {
                key: option.key,
                value: option.value,
                showInFrontend: option.showInFrontend,
                kitchenItem: option.kitchenItem,
              }
              return optionData
            })

            const properties = {
              id: field.id,
              parent: field.parent,
              type: field.type,
              file: field.base64File,
              label: field.label,
              baseLabel: field.baseLabel,
              description: field.description,
              required: field.required,
              displayInCalendar: field.displayInCalendar,
              multiSelectType: field.multiSelectType,
              extraOptions: extraOptionsData,
              showInFrontend: field.showInFrontend,
              kitchenItem: field.kitchenItem,
            }
            return properties
          })

          let sortedData = []
          trimmedData.forEach(obj => {
            if (!obj.parent) {
              sortedData.push(obj)
              const children = trimmedData.filter(o => o.parent === obj.id)
              sortedData = sortedData.concat(children)
            }
          })

          // const groupedMap = trimmedData.reduce(
          //   (entryMap, e) => entryMap.set(e.parent, [...entryMap.get(e.parent) || [], e]),
          //   new Map(),
          // )
          // let sortedData = []
          // groupedMap.forEach(value => {
          //   sortedData = sortedData.concat(value)
          // })
          // console.log(sortedData)

          formData.append('customFields', JSON.stringify(sortedData))

          this.$http.patch('operation/service-descriptions/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Changes have Saved',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.serviceDescription = response.data.data
                      this.setCustomField()
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customFields') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
